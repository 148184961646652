import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../shared/components/forms/Button";
import Input from "../../shared/components/forms/Input";
import { forgotPassword, forgotPasswordSubmit } from "../core/controllers/auth.controller";
import { Dialog } from "../../ui/dialogs/index";
import { getErrorMessage } from '../language/errors';

export default function ForgotPassword() {
    const [data, setData] = useState({
        name: '',
        code: '',
        password: '',
        rePassword: '',
        validate: false
    });

    const history = useHistory();

    const onChangeValues = (evt: React.ChangeEvent<HTMLInputElement>) => setData(
        {
            ...data,
            [evt.target.name]: evt.target.value
        }
    );

    const doForgotPassword = async () => {

        const result = await forgotPassword(data.name);
        
        if (result.error) 
        return Dialog.fire({
            icon: 'error',
            title: 'Error',
            text: getErrorMessage(result.error.name, result.error.message ),
        })
                
        Dialog.fire({
            icon: 'success',
            title: 'Codigo enviado',
            text: 'Se ha enviado un codigo de verificacion al correo: ' + result.data.Destination,
        })
        
        setData({
            ...data,
            validate: true
        })
    }

    const doForgotPasswordSubmit = async () => {

        if (data.password !== data.rePassword) {
            return Dialog.fire({
                icon: 'error',
                title: 'Error',
                text: 'Las contraseñas no coinciden',
            })
        }

        const result = await forgotPasswordSubmit(data.name, data.code, data.password);

        if (result.error) 
        return Dialog.fire({
            icon: 'error',
            title: 'Error',
            text: getErrorMessage(result.error.name, result.error.message ),
        })
                
        Dialog.fire({
            icon: 'success',
            title: 'Contraseña restablecida',
            text: 'Se ha restablecido su contraseña con exito'
        })
        
        history.push('/');
    }

    return (
        <>
            <Input
                className="mb-2"
                type="text"
                placeholder="Usuario"
                onChange={ data.validate ? () => {} : onChangeValues }
                value={data.name}
                name="name"
            />
            {
                data.validate ? (
                    <>

                        <Input
                            className="mb-2"
                            type="number"
                            placeholder="Código de verificación"
                            onChange={onChangeValues}
                            value={data.code}
                            name="code"
                        />
                        <Input
                            className="mb-2"
                            type="password"
                            placeholder="Nueva contraseña"
                            onChange={onChangeValues}
                            value={data.password}
                            name="password"
                        />
                        <Input
                            className="mb-2"
                            type="password"
                            placeholder="Confirmar contraseña"
                            onChange={onChangeValues}
                            value={data.rePassword}
                            name="rePassword"
                        />
                    </>) : null
            }

            <Button
                className="m-auto w-1/2 hover:opacity-70 mt-5 text-xl"
                onClick={ data.validate ? doForgotPasswordSubmit : doForgotPassword }
            >
                { data.validate ? 'Cambiar contraseña' : 'Enviar codigo' }
            </Button>
            <Link
                className="block m-auto mt-2 text-center hover:opacity-70 underline text-white text-base"
                to="/"
            >
                ¿Ya tienes cuenta? Inicia sesión
            </Link>
        </>
    );
}