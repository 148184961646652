import { loadModule } from '../modules/shared/hocs/ModuleLoaderHOC';
import './sass/AuthLayout.scss';

const AuthRouter = loadModule(() => import('../modules/auth'));

export default function AuthLayout() {
    return (
        <div className="app-auth-layout">
            <div>
                <img 
                    className="logo"
                    src="/assets/img/logo.svg"
                    alt=""
                />
                <AuthRouter />
            </div>
        </div>
    );
}

