import Header from "../modules/shared/components/ui/Header";
import Menu from "../modules/shared/components/ui/Menu";
import MobileMenu from "../modules/shared/components/ui/MobileMenu";
import { loadModule } from "../modules/shared/hocs/ModuleLoaderHOC";
import { Route, Switch } from "react-router-dom";
import './sass/DefaultLayout.scss';
import { DialogSystemProvider } from "../modules/shared/dialog-system";

const LazyModules = {
    Explore: loadModule(() => import('../modules/explore')),
    Categories: loadModule(() => import('../modules/categories')),
    Videos: loadModule(() => import('../modules/videos')),
    Photos: loadModule(() => import('../modules/photos')),
    Search: loadModule(() => import('../modules/search')),
    //Livestream: loadModule(() => import('../modules/livestream')),
}

export default function DefaultLayout() {
    return (
        <DialogSystemProvider>
            <div className="app-layout">
                <Header />
                <div className="wrapper">
                    <Menu />
                    <div className="content-wrapper">
                        <div className="content-section">
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={LazyModules.Explore}
                                />
                                <Route                            
                                    path="/categories"
                                    component={LazyModules.Categories}
                                />                                                
                                <Route
                                    path="/videos"
                                    component={LazyModules.Videos}
                                />
                                <Route
                                    path="/photos"
                                    component={LazyModules.Photos}
                                />                                                                                               
                                <Route
                                    exact
                                    path="/search"
                                    component={LazyModules.Search}
                                />                                                                  
                            </Switch>
                        </div>
                        <MobileMenu />
                    </div>
                </div>
            </div>
        </DialogSystemProvider>
    );
}