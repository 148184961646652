import { Auth } from "aws-amplify";
import { useEffect, useMemo, useReducer } from "react"
import { useHistory } from "react-router-dom";
import { AuthDispatchers } from "../models/auth-dispatchers.model";
import { AuthState } from "../models/auth-state.model";
import { Credentials } from "../models/credentials.model";
import { authReducer } from "../reducers/auth.reducer";

export const useAuth = (): [AuthState, AuthDispatchers] => {
    const history = useHistory();
    const [authState, dispatch] = useReducer(
        authReducer,
        {
            isLoading: true,
            isAuth: false,
            isAdmin: false,
            membership: 'none',
            username: ''
        }
    );

    const authDispatchers = useMemo(
        () => ({
            signIn: async (values: Credentials) => {
                try {
                    const loginResult = await Auth.signIn(values.email, values.password);
                    const subscriptionType = loginResult.signInUserSession.idToken.payload["subscriptionType"] || [];
                    console.log('Login result', subscriptionType, loginResult);

                    dispatch({ type: "SIGN_IN", membership: subscriptionType || 'none' });

                    const isSpecialRoute = 
                        window.location.pathname.startsWith('/memberships') ||
                        window.location.pathname.startsWith('/videos') ||
                        window.location.pathname.startsWith('/galeries');

                    if (!isSpecialRoute && !['premium'].includes(subscriptionType)) {
                        history.replace('/memberships', { referrer: window.location.pathname });
                    } else {
                        history.replace('/');
                    }

                    return { success: true, }
                } catch (err) {
                    return { success: false, error: err };
                }
            },
            signOut: async () => {
                try {
                    dispatch({ type: "LOADING" } as any);
                    await Auth.signOut();
                    dispatch({ type: "SIGN_OUT" } as any);
                    history.replace('/');
                } catch (err) {
                    console.log(err);
                    localStorage.clear();
                    dispatch({ type: "SIGN_OUT" } as any);
                    history.replace('/');
                }
            },
            getState() {
                return authState;
            }
        }),
        [history, authState]
    );

    useEffect(
        () => {
            const verifyToken = async () => {
                try {
                    const credentials = await Auth.currentSession();
                    const attributes = await Auth.currentUserInfo();
                    
                    if (Object.keys(attributes).length === 0)
                        throw new Error('Unauthenticated');

                    const payload: any = credentials.getIdToken().decodePayload();

                    // User has not membership
                    // if (groups.length === 0) {
                    //     history.replace('/payments/subscription')
                    // }

                    dispatch({ 
                        type: "SIGN_IN",
                        membership: payload.subscriptionType || 'none',
                        username: attributes.username as string
                    });

                    const isSpecialRoute = 
                        window.location.pathname.startsWith('/memberships') ||
                        window.location.pathname.startsWith('/videos') ||
                        window.location.pathname.startsWith('/galeries');

                    if (!isSpecialRoute && !['premium'].includes(payload.subscriptionType)) {
                        history.replace('/memberships', { referrer: window.location.pathname });
                    }
                } catch (err) {
                    console.error(err);
                    history.replace('/');
                    dispatch({ type: "SIGN_OUT" } as any)
                }
            }

            verifyToken();
        },
        []
    );

    return [authState, authDispatchers];
}