import React, { useContext, useState } from "react";
import Button from "../../shared/components/forms/Button";
import Input from "../../shared/components/forms/Input";
import  { confirmSignUp } from '../core/controllers/auth.controller';
import { AuthContext } from "../";
import { Dialog } from "../../ui/dialogs/index";
import { useHistory } from "react-router-dom";

interface VerificationCodeFormProps {
    formData: any;
}

export default function VerificationCodeForm({ formData }: VerificationCodeFormProps) {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [data, setData] = useState({
        code: ''
    });

    const onChangeValues = (evt: React.ChangeEvent<HTMLInputElement>) => setData(
        {
            ...data,
            [evt.target.name]: evt.target.value
        }
    );

    const doVerify = async () => {
        const result = await confirmSignUp(formData.email , data.code);
        
        if (result.error) 
            return Dialog.fire({
                icon: 'error',
                title: 'Error',
                text: result.error.message
            })

            Dialog.fire({
                icon: 'success',
                title: 'Cuenta verificada',
                text: 'Tu cuenta ha sido verificada con exito'
            })
        await authContext.signIn(formData);
        history.push('/');
    }

    return (
        <>
            <Input 
                className="mb-2"
                type="number" 
                placeholder="Codigo de verificacion"
                value={data.code}
                onChange={onChangeValues} 
                name="code"
            />
            <Button 
                className="m-auto w-1/2 hover:opacity-70 text-xl"
                onClick={doVerify}
            >
                Validar cuenta
            </Button>

        </>
    );
}
