import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './modules/auth';
import { loadModule } from './modules/shared/hocs/ModuleLoaderHOC';
import LoadingScreen from './modules/shared/screens/LoadingScreen';
import AuthLayout from './routes/AuthLayout';
import DefaultLayout from './routes/DefaultLayout';
import Disclaimer from './routes/Disclaimer';
import { ApplicationConfig } from './application.config';
import { Auth } from 'aws-amplify';
import { Provider } from 'use-http';
import './App.scss';

const LazyModules = {
	Memberships: loadModule(() => import('./modules/memberships')),
	User: loadModule(() => import('./modules/user')),
	Admin: loadModule(() => import('./modules/admin')),
}

function App() {
	const [authState, functions] = useAuth();
	const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);

	useEffect(
		() => {
			if (localStorage.getItem('IDA')) setIsDisclaimerAccepted(true);
		},
		[]
	);

	const beforeSend = async ({ options }: any) => {
		console.log('SETTING HEADERS.');
		if (!options.headers) options.headers = {} as any;
		
		// Retrieve cognito session.
		const session = await Auth.currentSession();

		(options.headers as any).Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
		(options.headers as any)['id-token'] = session.getIdToken().getJwtToken();
		
		return options;
	}

	if (authState.isLoading) return <LoadingScreen />;
	if (!isDisclaimerAccepted) return <Disclaimer handler={setIsDisclaimerAccepted} />;

	return (
		<AuthProvider value={functions}>
			<Provider
				options={{
					interceptors: {
						request: beforeSend as any
					}
				}}
				url={ApplicationConfig.api}
			>
				<Switch>
					{
						authState.isAuth ? (
							<Switch>
								{
									authState.isAdmin ? (
										<Route path="/admin" component={LazyModules.Admin} />
									) : (
										<Route path="/user" component={LazyModules.User} />
									)
								}
								<Route path="/memberships" component={LazyModules.Memberships} />
								<Route path="/" component={DefaultLayout} />
							</Switch>
						) : (
							<Route path="/" component={AuthLayout} />
						)
					}
				</Switch>
			</Provider>
		</AuthProvider>
	);
}

export default App;
