import Swal from 'sweetalert2';
import './sass/Dialog.scss';

export const Dialog = Swal.mixin({
    customClass: {
        // With Style
        container: 'component-dialog',
        popup: 'popup',
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
        // Currently without style 
        icon: '',
        image: '',
        input: 'form-control',
        actions: 'dialog-actions',
        closeButton: 'swal2-close',
        title: 'dialog-title',
        footer: 'dialog-footer',
        validationMessage: 'dialog-validation-message',
        denyButton: 'btn btn-secondary',
        htmlContainer: 'swal2-html-container',
        loader: 'swal2-loader',
        timerProgressBar: 'swal2-timer-progress-bar'
    },
    heightAuto: false
})