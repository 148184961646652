import { useState } from 'react';
import LoginForm from '../components/LoginForm';
import VerificationCodeForm from '../components/VerificationCodeForm';
export default function Login() {

    const [formData, setFormData] = useState({
        type: 0,
        email: '',
        password: ''
    });

    return (
        <>
        { formData.type === 0 && <LoginForm stateForm={setFormData}/> }
        { formData.type === 1 && <VerificationCodeForm formData={formData} /> }
        </>
    );
}