export const ERROR_MESSAGES = {
    'UserNotFoundException': 'Username does not exist',
    'NotAuthorizedException': 'The username or password are incorrect.',
    'unknown': 'An error occurred, please try again later',
    'UsernameExistsException': 'User already exists',
    'UserEmailExists': 'The email provided is already registered.',
    'InvalidParameterException': 'The user cannot be the email',
    'InvalidPasswordException': 'The password must contain a minimum of 8 characters, a capital letter and a number',
    'AuthError': 'Check your details',
    'LimitExceededException': 'Attempts exceeded, try again later',
    'ExpiredCodeException': 'The code has expired or is incorrect, please try again',
    'CodeMismatchException': 'The code is incorrect, please try again.',
}

export const getErrorMessage = (key: string, message?: string) => {
    console.log(key);
    if (key === 'UserLambdaValidationException')
        key = message?.indexOf('UserEmailExists') !== -1 ? (
            'UserEmailExists'
        ) : message?.indexOf('UsernameExistsException') ? (
            'UsernameExistsException'
        ) : (
            message
        ); 
    return key in ERROR_MESSAGES ? (ERROR_MESSAGES as any)[key] : ERROR_MESSAGES['unknown'];
}