import './sass/Button.scss';

export interface ButtonProps {
    children?: React.ReactNode;
    className?: string;
    style?: React.HTMLAttributes<HTMLInputElement>['style'];
    onClick?: () => void;
    disabled?: boolean;
}

export default function Button(props: ButtonProps) {
    return (
        <button
            disabled={props.disabled}
            className={`block bg-magenta-primary text-white rounded-full py-5
            drop-shadow-opaque px-8 hover:opacity-75 hover:cursor-pointer disabled:bg-magenta-primary disabled:bg-opacity-40 ${props.className ?? ''}`}
            style={props.style}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}