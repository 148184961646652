import { NavLink } from 'react-router-dom';
import './sass/Menu.scss';

export default function Menu() {
    return (
        <div className="shared-components-ui-menu">
            <nav>
                <NavLink to="/">
                    <div className="icon">
                        <img src="/assets/img/icons/explore.svg" alt="" />
                    </div>
                    <span>Explore</span>
                </NavLink>
                <NavLink to="/categories">
                    <div className="icon">
                        <img src="/assets/img/icons/categories.svg" alt="" />
                    </div>
                    <span>Categories</span>
                </NavLink>                
                <NavLink to="/videos">
                    <div className="icon">
                        <img src="/assets/img/icons/videos.svg" alt="" />
                    </div>
                    <span>Videos</span>
                </NavLink>
                <NavLink to="/photos">
                    <div className="icon">
                        <img src="/assets/img/icons/photos.svg" alt="" />
                    </div>
                    <span>Galeries</span>
                </NavLink>                
            </nav>
        </div>
    );
}