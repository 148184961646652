export const ApplicationConfig: IApplicationConfig = {
    env: process.env.REACT_APP_ENV,
    aws: {
        Auth: {
            userPoolId: process.env.REACT_APP_AUTH_ID,
            region: process.env.REACT_APP_AUTH_REGION,
            userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT
        }
    },
    api: process.env.REACT_APP_API_ENDPOINT,
    stripeClientKey: process.env.REACT_APP_STRIPE_CLIENT_KEY,
    mainURL: process.env.REACT_APP_MAIN_URL
} as any;

export interface IApplicationConfig {
    env: string;
    aws: {
        Auth: {
            userPoolId: string;
            region: string;
            userPoolWebClientId: string;
        }
    };
    api: string;
    stripeClientKey: string;
    videoIngestion: string;
    deliveryCDN: string;    
    mainURL: string;
    deliveryStorage: string;
}