import './sass/ProfileButton.scss';
import { AuthContext } from '../../../../modules/auth';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

export default function ProfileButton() {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const state = authContext.getState();

    return (
        <div className='user-img'>
            <div
                onClick={() => history.push(state.isAdmin ? '/admin' : '/user')}
            ></div>
        </div>
    );
}