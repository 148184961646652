import { AuthReducerAction } from "../models/auth-reducer-action.model";
import { AuthState } from "../models/auth-state.model";

export const authReducer: React.Reducer<AuthState, AuthReducerAction> = (prev, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return {
                isAuth: true,
                isLoading: false,
                isAdmin: action.isAdmin,
                membership: action.membership,
                username: action.username as string
            }
        case 'SIGN_OUT':
            return {
                isLoading: false,
                isAuth: false, 
                isAdmin: false,
                membership: 'none',
                username: ''
            }
        case 'LOADING':
            return {
                ...prev,
                isLoading: true,
                isAdmin: false,
                membership: 'none'
            }
        default:
            return prev;
    }
}