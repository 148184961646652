import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../shared/components/forms/Button";
import Input from "../../shared/components/forms/Input";
import { AuthContext } from "../";
import { getErrorMessage } from '../language/errors'
import { Dialog } from "../../ui/dialogs/index";

interface LoginFormProps {
    stateForm: any;
}

export default function LoginForm({ stateForm }: LoginFormProps) {
    const authContext = useContext(AuthContext);
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    const onChangeValues = (evt: React.ChangeEvent<HTMLInputElement>) => setData(
        {
            ...data,
            [evt.target.name]: evt.target.value
        }
    );

    const doLogin = async () => {
        const result = await authContext.signIn(data);

        if (result.error?.code === "UserNotConfirmedException")  {
            stateForm({
                type: 1,
                email: data.email,
                password: data.password
            });
        }

        if(!result.success) {
            Dialog.fire({
                icon: 'error',
                title: 'Error',
                text: getErrorMessage(result.error.code, result.error.message ),
                timer: 2500,
                showConfirmButton: false
            })
        }
    }

    return (
        <>
            <Input 
                className="mb-2"
                type="text"
                placeholder="Email"
                value={data.email}
                onChange={onChangeValues} 
                name="email"
            />
            <Input 
                className="mb-2"
                type="password" 
                placeholder="Password"
                value={data.password}
                onChange={onChangeValues} 
                name="password"
            />
            <Link 
                className="block mt-1 mb-5 text-right hover:opacity-70 text-white text-2xl"
                to="/forgot-password"
            >
                Forgot your password?
            </Link>
            <Button 
                className="m-auto w-1/2 hover:opacity-70 text-2xl"
                onClick={doLogin}
            >
                LOGIN
            </Button>
            <Link
                className="block m-auto text-center hover:opacity-70 underline text-white text-2xl mt-6"
                to="/register"
            >
                Don't have an account? Register
            </Link>
        </>
    );
}