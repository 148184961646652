import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../shared/components/forms/Button";
import Input from "../../shared/components/forms/Input";
import { signUp } from "../core/controllers/auth.controller";
import { Dialog } from "../../ui/dialogs/index";
import CenteredLoader from "../../shared/components/ui/CenteredLoader";

export default function Register() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        rePassword: ''
    });

    const history = useHistory();
    
    const onChangeValues = (evt: React.ChangeEvent<HTMLInputElement>) => setData(
        {
            ...data,
            [evt.target.name]: evt.target.value
        }
    );

    const doRegister = async () => {
        setLoading(true);
        const result = await signUp(data);

        if (result.error) {
            setLoading(false);
            return  Dialog.fire({
                icon: 'error',
                title: 'Oops...',
                text: result.error.message,
                timer: 3000,
            })
        }

        Dialog.fire({
            icon: 'success',
            title: 'Account created',
            text: "Please, login to continue.",
            timer: 3000,
        })

        history.push('/');
    }

    return (
        <>
            <Input 
                className="mb-2"
                type="text" 
                placeholder="Username"
                onChange={onChangeValues}
                value={data.name} 
                name="name"
            />
            <Input 
                className="mb-2"
                type="email" 
                placeholder="Email"
                onChange={onChangeValues}
                value={data.email} 
                name="email"
            />
            <Input 
                className="mb-2"
                type="password" 
                placeholder="Password"
                onChange={onChangeValues}
                value={data.password} 
                name="password"
            />
            <Input 
                className="mb-2"
                type="password" 
                placeholder="Confirm Password"
                onChange={onChangeValues}
                value={data.rePassword}
                name="rePassword"
            />
            {
                loading ? (
                    <CenteredLoader />
                ) : (
                    <Button
                        className="m-auto w-1/2 hover:opacity-70 mt-5 text-xl"
                        onClick={doRegister}
                    >
                        CREATE ACCOUNT
                    </Button>
                )
            }
            <Link 
                className="block m-auto mt-4 text-center hover:opacity-70 underline text-white text-2xl"
                to="/"
            >
                I have an account
            </Link>
        </>
    );
}