import Dialog from '@mui/material/Dialog';
import React, { useContext, useMemo, useState } from 'react';

export interface DialogContext {
    showDialog: (component: React.ReactNode, options?: { onClose?: (params?: any) => void; }) => void;
    closeDialog: (options?: { avoidOnClose?: boolean, params?: any }) => void;
}

export const DialogSystemContext = React.createContext<DialogContext>({} as any);
export const DialogSystemInternalProvider = DialogSystemContext.Provider;
export const DialogSystemInternalConsumer = DialogSystemContext.Consumer;

export const useDialogSystem = () => {
    return useContext(DialogSystemContext);
}

export function DialogSystemProvider({children} : { children: React.ReactNode | React.ReactNode}) {
    const [CurrentDialog, setCurrentDialog] = useState<any>(null);
    const [options, setOptions] = useState<any>(null);

    const contextValue = useMemo(
        () => ({
            async showDialog(component: React.ReactNode, options: any) {
                console.log('Showing component: ', component);
                setCurrentDialog(component);
                setOptions(options);
            },
            async closeDialog(opts?: { avoidOnClose?: boolean, params?: any }) {
                setCurrentDialog(null);
                if (opts?.avoidOnClose) return;
                options?.onClose?.(opts?.params);
            }
        }),
        [options]
    );
    return (
        <DialogSystemInternalProvider value={contextValue}>
            {children}
            <Dialog open={!!CurrentDialog}>
                {CurrentDialog}
            </Dialog>
        </DialogSystemInternalProvider>
    );
}

