import { NavLink } from "react-router-dom";
import './sass/MobileMenu.scss';

export default function MobileMenu() {
    return (
        <nav className="shared-components-ui-mobilemenu">
            <NavLink to="/">
                <div className="icon">
                    <img src="/assets/img/icons/explore.svg" alt="" />
                </div>
            </NavLink>
            <NavLink to="/categories">
                <div className="icon">
                    <img src="/assets/img/icons/categories.svg" alt="" />
                </div>
            </NavLink>            
            <NavLink to="/videos">
                <div className="icon">
                    <img src="/assets/img/icons/videos.svg" alt="" />
                </div>
            </NavLink>
            <NavLink to="/photos">
                <div className="icon">
                    <img src="/assets/img/icons/photos.svg" alt="" />
                </div>
            </NavLink>                              
        </nav>
    );
}