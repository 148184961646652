import Loader from "react-loaders";

export default function LoadingScreen() {
    return (
        <div className="flex items-center justify-center w-full h-full flex-col">
            <img src="/assets/img/logo.svg" alt="" className="w-4/12"/>
            <Loader 
                type="line-scale"
                active
            />
        </div>
    );
}