import { ReactNode } from "react"
import Button from "../forms/Button";

interface BannerLayoutProps {
	children: ReactNode
}
export default function BannerLayout({children}: BannerLayoutProps) {
	return (
        <div className="fixed top-0 left-0 w-full h-full z-10 text-xl font-Euclid 
        flex flex-col justify-start bg-gradient-to-l from-pink-primary to-blue-primary
        lg:flex-row lg:justify-end lg:pr-20 lg:bg-gradient-to-tr lg:via-blue-primary lg:to-white
        xl:pr-40">
            <div className='h-2/5 overflow-hidden flex justify-start
            lg:hidden'>
                <img src='/assets/img/banner.png' alt='banner' className='object-cover h-full w-full object-top ' />
            </div>
            <div className='w-full h-full hidden fixed top-0 left-0
            lg:flex'>
                <img src='/assets/img/bannerSlice.png' alt='logo' className='object-contain ' />
            </div>
            <div className='p-7 h-full flex justify-center items-center flex-col'>
                {children}
            </div>
        </div>
	)
}

