import Button from '../modules/shared/components/forms/Button';
import BannerLayout from '../modules/shared/components/layout/BannerLayout';
import './sass/AuthLayout.scss';
export interface DisclaimerProps {
    handler: (value: boolean) => void;
}

export default function Disclaimer(props: DisclaimerProps) {
    const acceptDisclaimer = () => {
        localStorage.setItem('IDA', 'true');
        props.handler(true);
    }

    return (
        <BannerLayout>
            <div className='w-full max-w-xl hidden z-10
            lg:flex'>
                <img src='/assets/img/logo.svg' alt='logo' className='object-cover h-full w-full object-top ' />
            </div>
            <div className='w-full max-w-2xl h-auto bg-black rounded-4xl text-white
            bg-gradient-to-t from-pink-primary to-blue-primary p-14 pt-20
            drop-shadow-opaque
            lg:max-w-4xl
            xl:max-w-5xl xl:py-32'>
                <p className='drop-shadow-text whitespace-pre-line text-2xl'>
                    {`The pages of this website are designed for ADULTS only and may include pictures and materials that some viewers may find offensive. If you are under the age of 18, if such material offends you or if it is illegal to view such material in your community please exit this site. Use of the site will constitute your agreement to the following terms and conditions:
                        1.) I am 18 years of age or older
                        2.) I accept all responsibility for my own actions; and
                        3.) I agree that I am legally bound to these Terms and Conditions
                        The content on this website was created for artistic and entertainment purposes.
                        It was not created with the intention to offend.
                    `}
                </p>
                <div className='flex flex-row space-x-5 justify-center mt-10 text-2xl
                lg:space-x-10'>
                    <button className='underline drop-shadow-text' onClick={()=>{
                        window.location.assign('https://google.com');
                    }}>
                        I don't agree.
                    </button>
                    <Button onClick={acceptDisclaimer}>
                        I agree, ENTER.
                    </Button>
                </div>
            </div>
        </BannerLayout>
    );
}