import Loader from "react-loaders";

export default function CenteredLoader() {
    return (
        <div className="flex w-full mt-2 mb-2 items-center justify-center">
            <Loader
                active
                type="line-scale"
            />
        </div>
    );
}