import { Route, Switch } from "react-router-dom";
import ForgotPassword from "./screens/ForgotPassword";
import Login from "./screens/Login";
import Register from "./screens/Register";

export default function AuthRouter() {
    return (
        <Switch>
            <Route 
                exact 
                path="/"
                component={Login} 
            />
            <Route 
                exact 
                path="/register"
                component={Register} 
            />
            <Route 
                exact 
                path="/forgot-password"
                component={ForgotPassword}  
            />
        </Switch>
    );
}