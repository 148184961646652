import './sass/Input.scss';

export interface InputProps {
    type: string;
    placeholder: string;
    className?: string;
    style?: React.HTMLAttributes<HTMLInputElement>['style'];
    value?: string;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
}

export default function Input(props: InputProps) {
    return (
        <input 
            className={"shared-components-forms-input " + (props.className ?? '')}
            style={props.style}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            name={props.name}
            value={props.value}
        />
    );
}